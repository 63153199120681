.navbar{
  text-align: center;
  background-color: #72bdc1;
  }
img{
  aspect-ratio: attr(width) / attr(height);
}
.prz-color{
  color: greenyellow;
}

